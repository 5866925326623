<template>
  <div>
    <b-modal
      ref="Tutorial"
      nome="Tutorial"
      :hide-footer="true"
      :hide-header="true"
      id="tutorial"
      @hidden="selectedVideo = {}"
      aspectRatio="16:9"
      size="lg"
      class="modal"
    >
      <LazyYoutube
        :src="selectedVideo.url"
        :autoplay="false"
        :showTitle="false"
        max-width="100%"
      />
    </b-modal>
    <Skeleton v-if="loading" />
    <two-cards sm="12" md="3" lg="3" xl="3" v-else>
      <template #header>
        <PageTitle title="Tutoriais"/>
      </template>
      <template #one>
        <div class="card invision-card elevate-2 borderless">
          <span class="ml-3 mt-3 text-caption2">CATEGORIAS</span>
          <b-list-group flush>
            <b-list-group-item
              v-for="(item, index) in categorias"
              :key="index"
              class="item padding"
              style="cursor:pointer"
              @click="changeSelectedCategoria(item.nome)">
              <b-row>
                <b-col align-self="start" cols="auto">
                  <div
                    class="number-circle text-menu-steps mt-1"
                    :class="indexOfSelectedCategoria === index ? 'number-circle-selected' : ''"
                  >
                    {{index + 1}}
                  </div>
                </b-col>
                <b-col align-self="start" cols="auto">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{{item.nome}}</h5>
                  </div>
                  <small class="text-muted">{{item.numeroVideos}} Vídeos</small>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </div>
      </template>
      <template #two>
        <h5 v-if="selectedCategoria">{{selectedCategoria}}</h5>
        <div v-if="selectedCategoria">
          <b-row
            class="white"
            v-for="(tutorial,index) in tutoriasByCategoria[selectedCategoria]"
            :key="index"
            >
            <b-col sm="4" md="4" lg="3" xl="3">
              <img :src=tutorial.thumbnailUrl class="thumbnail" @click="openVideo(tutorial.url)"/>
            </b-col>
            <b-col>
              <b-row>
                <b-col>
                  <span class="text-caption2" style="text-transform: uppercase">
                    {{tutorial.subcategoria}}
                  </span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <h5>{{tutorial.descricao}}</h5>
                </b-col>
              </b-row>
                <div class="toast-qrcode" :class="indexLinkCopy === index ? 'd-block': 'd-none'">
                  <span>Link copiado com sucesso</span>
                </div>
              <b-row>
                <b-col>
                  <div class="d-inline-flex col-qr-code align-middle" @click="copyVideoUrl(tutorial.url, index)">
                    <h4 class=" text-qr-code">{{tutorial.url}}</h4>
                    <copy-icon class="icon-copy ml-12 align-self-center"/>
                  </div>
                  <Button
                    text="Assistir Video"
                    icon="play-circle"
                    size="md"
                    buttonClass="align-middle"
                    @click="openVideo(tutorial)"
                  ></Button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </template>
    </two-cards>

  </div>
</template>

<script>
import { LazyYoutube } from 'vue-lazytube';
import GenericApi from '@/services/genericRequest';
import TwoCards from '@/templates/TwoCards';
import Button from '@/components/Utils/Button';
import PageTitle from '@/templates/PageTitle';
import Skeleton from './Skeleton';

export default {
  components: {
    TwoCards,
    Button,
    PageTitle,
    Skeleton,
    LazyYoutube,
  },

  data() {
    return {
      loading: true,
      showToast: false,
      selectedVideo: {},
      tutoriasByCategoria: {},
      categorias: [],
      selectedCategoria: '',
      thumbnailUrls: [],
      indexLinkCopy: -1,
    };
  },

  computed: {
    classToast() {
      if (this.showToast) return 'd-block';
      return 'd-none';
    },
    indexOfSelectedCategoria() {
      return this.categorias.findIndex((categoria) => categoria.nome === this.selectedCategoria);
    },
  },

  async mounted() {
    const videos = await this.getAllTutorias();
    this.divideTutoriaisByCategoria(videos);
    if (this.categorias.length) {
      this.selectedCategoria = this.categorias[0].nome;
    }
  },

  methods: {
    changeSelectedCategoria(categoria) {
      this.selectedCategoria = categoria;
    },
    divideTutoriaisByCategoria(tutorias) {
      // myString.replace(/^\w/, (c) => c.toUpperCase());
      this.tutoriasByCategoria = tutorias.reduce((result, tutorial) => {
        const { categoria } = tutorial;
        const categoriaCapitalized = categoria.replace(/^\w/, (c) => c.toUpperCase()).trim();
        if (!result[categoriaCapitalized]) result[categoriaCapitalized] = [];
        const thumbnailUrl = this.createThumbnailUrl(tutorial.url);
        result[categoriaCapitalized].push({ ...tutorial, thumbnailUrl });
        return result;
      }, {});
      this.categorias = Object.keys(this.tutoriasByCategoria).map((categoria) => {
        const categoriaCapitalized = categoria.replace(/^\w/, (c) => c.toUpperCase()).trim();
        return {
          nome: categoriaCapitalized,
          numeroVideos: this.tutoriasByCategoria[categoria].length,
        };
      });
    },

    createThumbnailUrl(videoUrl) {
      // eslint-disable-next-line no-useless-escape
      const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      const match = videoUrl.match(regExp);
      const id_video = match[2];
      return `//img.youtube.com/vi/${id_video}/0.jpg`;
    },

    async getAllTutorias() {
      let result = null;
      this.loading = true;
      try {
        const route = 'tutorial';
        result = await GenericApi.getWithoutPagination(
          {},
          route,
        );
      } catch (error) {
        let errorText = 'Ocorreu um problema ao recuperar os tutoriais';
        if (
          error.response
          && error.response.data
          && error.response.data.error
          && error.response.data.error.errorMessage
        ) {
          errorText = error.response.data.error.errorMessage;
        }
        result = null;
        await swal({
          title: 'Erro',
          text: errorText,
          icon: 'error',
          button: 'Continuar...',
        });
      }
      this.loading = false;
      return result;
    },
    copyVideoUrl(url, index) {
      const textField = document.createElement('textarea');
      textField.innerText = url;
      document.body.appendChild(textField);
      if (window.navigator.platform === 'iPhone') {
        textField.setSelectionRange(0, 99999);
      } else {
        textField.select();
      }
      document.execCommand('copy');
      textField.remove();
      this.indexLinkCopy = index;
      setTimeout(() => {
        this.indexLinkCopy = -1;
      }, 2000);
    },

    openVideo(videoObject) {
      this.selectedVideo = videoObject;
      this.$bvModal.show('tutorial');
    },
  },
};
</script>

<style lang="scss" scoped>
$neutral-color-10: #FFFFFF;
$neutral-color-60: #7E829F;
$neutral-color-70: #5E627A;
$neutral-color-80: #35384D;

h5 {
  line-height: 140%;
  font-weight: 500;
  font-size: 18px;
  color: $neutral-color-80;
}

.white {
  background-color: white;
  padding: 15px;
  max-width: 750px;
  margin-bottom: 10px;
}

.thumbnail {
  height: 100px;
  width: 155px;
  border-radius: 5px;
  cursor: pointer;
}

.toast-qrcode {
  position: absolute;
  top: -30px;
  background-color: rgba(126, 130, 159, 0.8);
  color: white;
  padding: 5px 8px;
  border-radius: 5px;
  font-size: 12px;
}

.col-qr-code{
  text-align: justify;
  background-color: #F0F1FC;
  border-radius: 5px 0px 0px 5px;
  padding: 10px 16px;
  cursor: pointer;
  height: 40px;
}

.col-qr-code h4 {
  margin: 0;
  max-width: 85%;

}

.text-qr-code {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  width: 15rem;
  display:inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* identical to box height */
  /* Neutral / neutral-color-60 */
  color: #7E829F;
}

.icon-copy {
  color: #7E829F;
}

.ml-12 {
  margin-left: 12px;
}

.number-circle {
    border-radius: 50%;
    width: 27px;
    height: 27px;
    background: $neutral-color-10;
    border: 2px solid $neutral-color-80;
    padding: 1px;
    color: $neutral-color-80;
    text-align: center;
    display: inline-block;
}

.number-circle-selected {
  background: $neutral-color-80 !important;
  color: $neutral-color-10 !important;
}
</style>
